import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Dayjs } from 'dayjs';
import { saveAs } from 'file-saver';

import { useElementSize } from 'usehooks-ts';

import { useAppDispatch, useAppSelector } from '@store/hooks';

import {
    Table,
    Button,
    Input,
    DatePicker,
    Modal,
    Tooltip,
    Form,
    Switch,
    InputNumber,
    Flex,
    Space,
    Checkbox,
    Select,
    notification,
    Tag,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import type { UploadProps } from 'antd/es/upload';

import {
    LoadingOutlined,
    ReloadOutlined,
    EyeFilled,
    FilterFilled,
    CalendarOutlined,
    EditOutlined,
    CheckCircleFilled,
    AppstoreAddOutlined,
    UnorderedListOutlined,
    QuestionCircleOutlined,
    FileExcelOutlined,
    CheckOutlined,
    WarningOutlined,
    FormOutlined,
    MessageFilled,
    PercentageOutlined,
} from '@ant-design/icons';

import FormHeader from '@controls/form-header/form-header';
import Filter from '@controls/filter/filter';
import Toolbar from '@controls/toolbar/toolbar';
import Packages from '@src/components/logistics/import/bills/packages';

import Bill from '@src/core/controls/bill/bill';
import BillModal from '@components/balances/bill-modal';

import { serverFetch } from '@src/core/server';

import { exception, securityRestriction } from '@extensions/notification';
import { delayAction, toFinanceString, dataURItoBlob } from '@extensions/utils';
import { userLoaded, setFilter } from '@store/actions';

import { IUserSession } from '@entities/user-session';
import { IBillFilter } from '@src/core/entities/bill-filter';
import { IBill } from '@src/core/entities/bill';
import { IUser } from '@entities/user';
import { ICity } from '@entities/city';
import { ITruck } from '@entities/truck';
import { IConsigneeTariffSetting } from '@entities/consignee-tariff-setting';
import { IBillPackage } from '@entities/bill-package';

import { Permission, hasPermission } from '@enums/permission';
import { UnitType } from '@enums/unit-type';
import { WeightType } from '@enums/weight-type';
import { Currency, enumSign as currencySign } from '@enums/currency';
import { BillStatus } from '@src/core/enums/bill-status';
import { UserType } from '@enums/user-type';

import { TruckIcon, AirplaneIcon, SumIcon, PenIcon, RefundIcon, CombinedIcon, BoxesIcon } from '@icons/index';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface IBillHeader {
    billDate?: Dayjs;
    totalPrice: number;
    totalPriceFinal: number;
    totalPriceRub: number;
    totalPriceFinalRub: number;
    bills: Array<IBill>;
}

interface loadingOnParams {
    billIds: Array<string>;
    loadingOn: Dayjs;
}

const filterContext: string = 'BillHeaders';

const BillHeaders = () => {
    const { userId } = useParams();

    const initFilter: IBillFilter = {
        userId: userId,
        completedOnly: true,
        isPaid: false,
        isArchived: false,
        statuses: [BillStatus.Completed],
    };

    const [containerRef, { width, height }] = useElementSize();
    const [filterRef, { width: filterWidth, height: filterHeight }] = useElementSize();

    const d = useAppDispatch();

    var Buffer = require('buffer/').Buffer;

    const [editTariffForm] = Form.useForm();
    const [loadingOnForm] = Form.useForm();

    const { RangePicker } = DatePicker;

    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const filter = useAppSelector<IBillFilter>((s) => s.filters[filterContext]);

    const [modal, modalContextHolder] = Modal.useModal();
    const [api, notificationContextHolder] = notification.useNotification();

    const [showFilter, setShowFilter] = useState<boolean>(true);

    const [headers, setHeaders] = useState<Array<IBillHeader>>([]);
    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [openBill, setOpenBill] = useState<boolean>(false);
    const [bills, setBills] = useState<Array<IBill>>([]);
    const [currentBill, setCurrentBill] = useState<IBill>();
    const [user, setUser] = useState<IUser>();
    const [editTariffOpen, setEditTariffOpen] = useState<boolean>(false);
    const [cities, setCities] = useState<Array<ICity>>([]);
    const [tariffSettings, setTariffSettings] = useState<Array<IConsigneeTariffSetting>>([]);
    const [trucks, setTrucks] = useState<Array<ITruck>>([]);
    const [contractors, setContractors] = useState<Array<IUser>>([]);

    const [packages, setPackages] = useState<Array<IBillPackage>>([]);
    const [openPackageDialog, setOpenPackageDialog] = useState<boolean>(false);
    const [openloadingOnDialog, setOpenloadingOnDialog] = useState<boolean>(false);

    const [openBillDialog, setOpenBillDialog] = useState<boolean>(false);

    const setCityRequired = Form.useWatch('setCityRequired', editTariffForm);
    const setVolumeWeightRequired = Form.useWatch('setVolumeWeightRequired', editTariffForm);
    const setGrossWeightRequired = Form.useWatch('setGrossWeightRequired', editTariffForm);

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        setOpenPackageDialog(false);

        setSelectedIds([]);
        setHeaders([]);

        const fetchData = async () => {
            editTariffForm.resetFields();

            setLoading(true);

            let promises = [
                await serverFetch('bills/headers', { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения счетов', ex, () => d(userLoaded(undefined)));
                    }),
                await serverFetch(`users/info/${userId}`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения пользователя', ex, () => d(userLoaded(undefined)));
                    }),
                await serverFetch('cities', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения городов', ex, () => d(userLoaded(undefined)));
                    }),
                await serverFetch(`consignees/tariffsettings`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения настроек тарифа грузополучателей', ex, () => d(userLoaded(undefined)));
                    }),
                await serverFetch('bills/trucks', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения машин', ex, () => d(userLoaded(undefined)));
                    }),
                await serverFetch('users', { method: 'GET', queryParams: { types: UserType.Supplier, isActiveOnly: true } })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения поставщиков', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setHeaders(result[0][0]);
                setUser(result[0][1]);
                setCities(result[0][2]);
                setTariffSettings(result[0][3]);
                setTrucks(result[0][4].sort((a: ITruck, b: ITruck) => (dayjs(a.departureOn).isAfter(dayjs(b.departureOn)) ? -1 : 1)));
                setContractors(result[0][5]);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        if (currentBill && currentBill.packages) setPackages(currentBill.packages);
        else setPackages([]);
    }, [currentBill]);

    useEffect(() => {
        if (!filter || filter.userId != userId) {
            d(setFilter(initFilter, filterContext));
            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    useEffect(() => {
        if (bills && bills.length > 0) {
            setOpenBill(true);
        }
    }, [bills]);

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            headers.map((p) => {
                p.bills.map((b) => {
                    if (b.id === selectedRowKeys[0]) {
                        setCurrentBill(b);
                    }
                });
            });
        } else {
            setCurrentBill(undefined);
        }
    };

    const onBillUpdate = (entity: IBill) => {
        serverFetch(`bills/price`, { method: 'PUT', bodyData: entity })
            .then(() => {
                editTariffForm.resetFields();

                setEditTariffOpen(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка изменения счета', ex, () => d(userLoaded(undefined)));
            });
    };

    const onSetloadingOn = (entity: loadingOnParams) => {
        entity.billIds = selectedIds as string[];
        entity.loadingOn = dayjs(entity.loadingOn).utc(true).set('hour', 0).set('minute', 0).set('second', 0);

        serverFetch(`bills/loadingOn`, { method: 'POST', bodyData: entity })
            .then(() => {
                loadingOnForm.resetFields();

                setOpenloadingOnDialog(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка изменения счета', ex, () => d(userLoaded(undefined)));
            });
    };

    const onRemove = (id: string): Promise<string> => {
        return serverFetch(`bills/documents/file/${id}/delete`, { method: 'DELETE' })
            .then((data) => {
                return data;
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка удаления файла', ex, () => d(userLoaded(undefined)));
                return undefined;
            });
    };

    const onSavePackages = () => {
        setLoading(true);

        let entities = packages.filter((p) => !!p.boxQty);

        serverFetch(`bills/${currentBill?.id}/packages`, { method: 'POST', bodyData: entities })
            .then(() => {
                setOpenPackageDialog(false);
                setRefreshRequired(true);
                setCurrentBill(undefined);
                setSelectedIds([]);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка добавления коробок', ex, () => d(userLoaded(undefined)));
            });
    };

    const onUpload = async (id: string, file: Blob): Promise<string> => {
        const formData = new FormData();
        formData.append('file', file);

        return serverFetch(`bills/${id}/invoice/upload`, { method: 'POST', body: formData })
            .then((data) => {
                return data;
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка загрузки файла', ex, () => d(userLoaded(undefined)));
                return undefined;
            });
    };

    const onRevertPayment = () => {
        return serverFetch(`bills/revertpayment`, { method: 'POST', bodyData: { billIds: selectedIds } })
            .then(() => {
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка отмены платежей', ex, () => d(userLoaded(undefined)));
            });
    };

    const handleChange: UploadProps['onChange'] = async ({ file, fileList }) => {
        if (!file) return;

        var tariffId = file.response;

        if (file.status == 'uploading') {
            var result = await onUpload(tariffId, file.originFileObj as Blob);

            if (result) {
                file.status = 'done';
                file.uid = result;
            }
        } else if (file.status == 'removed') {
            var result = await onRemove(file.uid);
            if (result) {
                file.status = 'done';
            }
        }

        let tmpHeaders = [...headers];
        tmpHeaders.map((h) => {
            let bill = h.bills.find((b) => b.id == tariffId);
            if (bill) {
                bill.documents = fileList;
            }
        });

        setHeaders(tmpHeaders);
    };

    const handlePreview = async (file: any) => {
        if (file) {
            if (!file.url && !file.preview) {
                var fileObj = file.originFileObj;

                if (typeof fileObj !== 'object') {
                    const buffer = new Buffer.from(file.originFileObj, 'base64');
                    fileObj = new Blob([buffer], { type: 'application/pdf' });
                }
            }

            var fileURL = URL.createObjectURL(fileObj);
            window.open(fileURL);
        }
    };

    const onExport = () => {
        serverFetch('bills/export', { method: 'GET', queryParams: filter })
            .then((data) => {
                let fileBody = `data:${data.contentType};base64,${data.fileContents}`;
                saveAs(dataURItoBlob(fileBody), data.fileDownloadName);
            })
            .catch((ex) => {
                exception(api, 'Ошибка выгрузки счетов', ex, () => d(userLoaded(undefined)));
            });
    };

    const onCalculateDiscount = () => {
        setLoading(true);

        serverFetch(`bills/discount`, { method: 'POST', bodyData: selectedIds })
            .then(() => {
                setRefreshRequired(true);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка расчета скидки', ex, () => d(userLoaded(undefined)));
            });
    };

    const onSaveBill = (entity: IBill) => {
        if (!entity) return;

        serverFetch(`bills`, { method: entity.id ? 'PUT' : 'POST', bodyData: entity })
            .then(() => {
                setOpenBillDialog(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка выставления счета', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderFilter = () => {
        return (
            <div ref={filterRef}>
                <Filter
                    items={[
                        <Input
                            style={{ width: 130 }}
                            key='number'
                            placeholder='Номер счета'
                            value={filter?.number}
                            onChange={(data) => {
                                d(setFilter({ ...filter, number: data.target.value }, filterContext));
                            }}
                        />,
                        <Input
                            style={{ width: 130 }}
                            key='consigneeCode'
                            placeholder='ID клиента'
                            value={filter?.consigneeCode}
                            onChange={(data) => {
                                d(setFilter({ ...filter, consigneeCode: data.target.value }, filterContext));
                            }}
                        />,
                        <Input
                            style={{ width: 130 }}
                            key='markingCode'
                            placeholder='Маркировка'
                            value={filter?.markingCode}
                            onChange={(data) => {
                                d(setFilter({ ...filter, markingCode: data.target.value }, filterContext));
                            }}
                        />,
                        <Input
                            style={{ width: 230 }}
                            key='legalName'
                            placeholder='Контрагент'
                            value={filter?.legalName}
                            onChange={(data) => {
                                d(setFilter({ ...filter, legalName: data.target.value }, filterContext));
                            }}
                        />,
                        <Select
                            maxTagCount='responsive'
                            key='contractor'
                            placeholder='Поставщик'
                            value={filter?.contractorIds}
                            size='middle'
                            mode='multiple'
                            allowClear={true}
                            style={{ width: 200 }}
                            showSearch
                            onChange={(value) => d(setFilter({ ...filter, contractorIds: value }, filterContext))}
                            optionFilterProp='children'
                            filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={contractors.map((c) => {
                                return { value: c.id, label: c.login };
                            })}
                        />,
                        <Input
                            style={{ width: 130 }}
                            key='awbNumber'
                            placeholder='AWB'
                            value={filter?.awbNumber}
                            onChange={(data) => {
                                d(setFilter({ ...filter, awbNumber: data.target.value }, filterContext));
                            }}
                        />,
                        <Select
                            style={{ width: 180 }}
                            key='truck'
                            placeholder='Машина'
                            value={filter?.truckIds}
                            allowClear={true}
                            size='middle'
                            mode='multiple'
                            maxTagCount='responsive'
                            showSearch
                            onChange={(value: any) => d(setFilter({ ...filter, truckIds: value }, filterContext))}
                            filterOption={(input: any, option: any) =>
                                (option?.label as string).toLowerCase().startsWith(input.toLowerCase())
                            }
                            options={trucks.map((t) => {
                                return { value: t.id, label: `${dayjs.utc(t.departureOn).local().format('DD.MM.YYYY')} / ${t.number}` };
                            })}
                        />,
                        <Select
                            key='city'
                            placeholder='Город доставки'
                            value={filter?.cityIds}
                            size='middle'
                            mode='multiple'
                            maxTagCount='responsive'
                            allowClear
                            style={{ width: 200 }}
                            onChange={(value) => d(setFilter({ ...filter, cityIds: value }, filterContext))}
                            filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={cities.map((s) => {
                                return { value: s.id, label: s.name };
                            })}
                        />,
                        <RangePicker
                            style={{ width: 220 }}
                            allowEmpty={[true, true]}
                            key='loading'
                            format='DD.MM.YYYY'
                            placeholder={['Отгрузка с', 'до']}
                            cellRender={(current) => {
                                return <div className='ant-picker-cell-inner'>{(current as Dayjs).date()}</div>;
                            }}
                            value={[
                                filter?.loadingFrom ? dayjs(filter?.loadingFrom) : null,
                                filter?.loadingTo ? dayjs(filter?.loadingTo) : null,
                            ]}
                            onChange={(value) => {
                                if (!value) {
                                    d(
                                        setFilter(
                                            {
                                                ...filter,
                                                loadingFrom: undefined,
                                                loadingTo: undefined,
                                            },
                                            filterContext
                                        )
                                    );

                                    return;
                                }

                                d(
                                    setFilter(
                                        {
                                            ...filter,
                                            loadingFrom: value[0]
                                                ? dayjs(value[0]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                                : undefined,
                                            loadingTo: value[1]
                                                ? dayjs(value[1]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                                : undefined,
                                        },
                                        filterContext
                                    )
                                );
                            }}
                        />,
                        <Form.Item
                            key='withFlightOnly'
                            label={
                                <Tooltip title='Только с перелетом'>
                                    <Flex align='center'>
                                        <AirplaneIcon style={{ color: '#000000' }} />
                                    </Flex>
                                </Tooltip>
                            }
                            style={{ margin: 0 }}
                        >
                            <Switch
                                checked={filter?.withFlightOnly}
                                onChange={(value: any) => {
                                    d(setFilter({ ...filter, withFlightOnly: value }, filterContext));
                                }}
                            />
                        </Form.Item>,
                        <Form.Item key='isPaid' label='Оплаченные' style={{ margin: 0 }}>
                            <Switch
                                checked={filter?.isPaid}
                                onChange={(value: boolean) => {
                                    d(setFilter({ ...filter, isPaid: value }, filterContext));
                                }}
                            />
                        </Form.Item>,
                        <Form.Item key='isChanged' label='Измененные' style={{ margin: 0 }}>
                            <Switch
                                checked={filter?.isChanged}
                                onChange={(value: boolean) => {
                                    d(setFilter({ ...filter, isChanged: value }, filterContext));
                                }}
                            />
                        </Form.Item>,

                        <Form.Item key='isArchived' label='Архив' style={{ margin: 0 }}>
                            <Switch
                                checked={filter?.isArchived}
                                onChange={(value: any) => {
                                    d(setFilter({ ...filter, isArchived: value }, filterContext));
                                }}
                            />
                        </Form.Item>,
                    ]}
                    onClear={() => d(setFilter(initFilter, filterContext))}
                />
            </div>
        );
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: `${
                            hasPermission(userSession.permissions, Permission.ManageTransportTariffs) ? 'Изменить' : 'Информация о счете'
                        }`,
                        key: 'edit',
                        disabled: !currentBill,
                        icon: <EditOutlined />,
                        onClick: () => {
                            if (currentBill?.boxGroupId) {
                                if (!hasPermission(userSession.permissions, Permission.ManageTransportTariffs)) {
                                    securityRestriction(api, [Permission.ManageTransportTariffs]);
                                    return;
                                }

                                editTariffForm.setFieldsValue({
                                    id: currentBill?.id,
                                    boxGroupId: currentBill?.boxGroupId,
                                    cityId: currentBill?.cityId,
                                    volumeWeight: currentBill?.volumeWeight,
                                    grossWeight: currentBill?.grossWeight,
                                    flightPrice: currentBill?.flightPrice,
                                    truckPrice: currentBill?.truckPrice,
                                    totalPrice: currentBill?.totalPrice,
                                    adjustmentQty: currentBill?.adjustmentQty,
                                });

                                setEditTariffOpen(true);
                            } else {
                                setOpenBillDialog(true);
                            }
                        },
                    },
                    {
                        label: 'Изменить коробки',
                        key: 'boxes',
                        disabled: loading || !currentBill || currentBill?.unitType != UnitType.Pallet,
                        icon: <AppstoreAddOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageTransportTariffs)) {
                                securityRestriction(api, [Permission.ManageTransportTariffs]);
                                return;
                            }

                            setOpenPackageDialog(true);
                        },
                    },
                    {
                        label: 'Изменить дату отгрузки',
                        key: 'setLoadingOn',
                        disabled: loading || selectedIds.length <= 0,
                        icon: <CalendarOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageTransportTariffs)) {
                                securityRestriction(api, [Permission.ManageTransportTariffs]);
                                return;
                            }

                            setOpenloadingOnDialog(true);
                        },
                    },
                    {
                        label: 'Отменить оплату',
                        key: 'refund',
                        disabled: loading || !filter?.isPaid || selectedIds.length <= 0,
                        icon: <RefundIcon />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.AllowRevertPayment)) {
                                securityRestriction(api, [Permission.AllowRevertPayment]);
                                return;
                            }

                            modal.confirm({
                                title: `Отменить платежи для выбранных счетов?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'ОК',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onRevertPayment();
                                },
                            });
                        },
                    },
                    {
                        label: 'Рассчитать скидку',
                        key: 'calculate',
                        disabled: loading,
                        icon: <PercentageOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageTariff)) {
                                securityRestriction(api, [Permission.ManageTariff]);
                                return;
                            }

                            modal.confirm({
                                title: `Пересчитать итоговую сумму с учетом скидок?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'ОК',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onCalculateDiscount();
                                },
                            });
                        },
                    },
                    {
                        label: 'Выгрузить в Excel',
                        key: 'exportToExcel',
                        icon: <FileExcelOutlined />,
                        onClick: () => onExport(),
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const expandedBills = (record: IBillHeader) => {
        const columns: ColumnsType<IBill> = [
            {
                title: ' ',
                align: 'center',
                width: 40,
                render: (_, record) => {
                    return (
                        <>
                            <Flex>
                                <Tooltip title='Открыть счет'>
                                    <Button type='text' icon={<EyeFilled />} onClick={() => setBills([record])} />
                                </Tooltip>
                                {record.boxGroupId && (
                                    <Tooltip title='Детали заказа'>
                                        <Button
                                            type='text'
                                            target='_blank'
                                            href={`/orders/${currentBill?.userId}/${currentBill?.boxGroupId}`}
                                            icon={<UnorderedListOutlined />}
                                        />
                                    </Tooltip>
                                )}
                            </Flex>
                            {record.status == BillStatus.ToVerify && (
                                <Tooltip placement='top' title='Данные были изменены' color='var(--main-red)'>
                                    <WarningOutlined
                                        style={{
                                            color: 'var(--main-red)',
                                            fontSize: 18,
                                            marginTop: 5,
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </>
                    );
                },
            },
            {
                title: '#',
                align: 'center',
                dataIndex: 'number',
                width: 80,
            },
            {
                title: 'ID',
                align: 'center',
                width: 80,
                render: (_, record) => {
                    if (!record.boxGroupId) return;

                    return (
                        <>
                            <div>{record.consigneeCode}</div>
                            <div>
                                {record.isCombined && (
                                    <Tooltip
                                        placement='top'
                                        title={`Объединение грузов c машиной: ${record.combinedTruckNumbers}`}
                                        color='blue'
                                    >
                                        <CombinedIcon style={{ color: 'blue', fontSize: 18, marginTop: 5 }} />
                                    </Tooltip>
                                )}
                            </div>
                        </>
                    );
                },
            },
            {
                title: ' ',
                align: 'center',
                width: 40,
                render: (_, record) => {
                    return (
                        record.isChanged && (
                            <Tooltip title={`Счет изменен ${dayjs.utc(record.changedOn).local().format('DD.MM.YYYY')}`}>
                                <PenIcon />
                            </Tooltip>
                        )
                    );
                },
            },
            {
                title: 'Маркировка',
                dataIndex: 'markingCode',
                width: 180,
            },
            {
                title: 'Контрагент',
                dataIndex: 'legalName',
                width: 250,
            },
            {
                title: 'Поставщик',
                width: 130,
                dataIndex: 'contractorName',
            },
            {
                title: 'Расчетный город',
                dataIndex: 'cityName',
                width: 180,
            },
            {
                title: 'Страна',
                dataIndex: 'countryName',
                width: 130,
            },
            {
                title: '',
                width: 250,
                onCell: () => ({
                    style: {
                        paddingTop: 0,
                    },
                }),
                render: (_, record) => {
                    var diffQty: number = 0;
                    var useAdjustmentQty: boolean = false;

                    if (record.qty && record.unitType != UnitType.Weight) {
                        if (record.packages && record.packages.length > 0) {
                            var actualQty: number = 0;

                            record.packages &&
                                record.packages.map((p) => {
                                    actualQty += p.boxQty || 0;
                                });

                            diffQty = actualQty - record.qty;
                        } else {
                            useAdjustmentQty = true;
                        }
                    }

                    if (!record.boxGroupId) return record.comment;

                    return (
                        <Form colon={false} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} style={{ marginBottom: 5 }}>
                            <Form.Item
                                label={
                                    <Tooltip title='Количество коробок'>
                                        <BoxesIcon />
                                    </Tooltip>
                                }
                                className='country-form-item'
                                style={{ fontWeight: 600 }}
                            >
                                <Tag color='purple' style={{ marginBottom: 2 }}>
                                    {record.itemName}
                                </Tag>
                                {record.qty}
                                {useAdjustmentQty ? (
                                    <span style={{ marginLeft: 2, marginRight: 2 }}>({record.adjustmentQty})</span>
                                ) : (
                                    diffQty != 0 && (
                                        <span style={{ color: diffQty > 0 ? 'green' : 'red', marginLeft: 2, marginRight: 2 }}>
                                            (<span>{diffQty > 0 && '+'}</span>
                                            <span>{diffQty}</span>)
                                        </span>
                                    )
                                )}
                            </Form.Item>
                            {record.loadingOn && (
                                <Form.Item
                                    label={
                                        <Tooltip title='Дата отгрузки'>
                                            <CalendarOutlined style={{ fontSize: 18 }} />
                                        </Tooltip>
                                    }
                                    className='country-form-item'
                                >
                                    <span style={{ backgroundColor: '#FFDD2D', padding: '0 4px' }}>
                                        {dayjs.utc(record.loadingOn).local().format('DD.MM.YYYY')}
                                    </span>
                                </Form.Item>
                            )}
                            {record.awbNumber && (
                                <Form.Item label='AWB' className='country-form-item'>
                                    <span style={{ backgroundColor: '#efefef', padding: '0 4px' }}>{record.awbNumber}</span>
                                </Form.Item>
                            )}
                            {record.cargoName && (
                                <Form.Item label={<AirplaneIcon style={{ fontSize: 14, marginLeft: 5 }} />} className='country-form-item'>
                                    <Tag color='var(--main-green)'>{record.cargoName}</Tag>
                                </Form.Item>
                            )}
                            {record.truckNumber && (
                                <Form.Item
                                    label={
                                        <Tooltip title='Номер машины'>
                                            <TruckIcon />
                                        </Tooltip>
                                    }
                                    className='country-form-item'
                                >
                                    <span>{record.isCombined ? record.combinedTruckNumbers : record.truckNumber}</span>
                                </Form.Item>
                            )}
                        </Form>
                    );
                },
            },
            {
                title: 'Вес Брутто',
                width: 120,
                align: 'center',
                render: (_: any, record) => {
                    if (record.unitType !== UnitType.Weight) return '';

                    let setting = tariffSettings.find((s) => s.consigneeId == record?.consigneeId && s.countryId == record?.countryId);

                    return (
                        record.grossWeight && (
                            <>
                                {record.grossWeight} <span style={{ fontWeight: 500 }}>кг</span>
                                {setting && setting.weightType == WeightType.Gross && (
                                    <Tooltip title='Расчетный вес'>
                                        <CheckCircleFilled style={{ color: '#FFDD2D', marginLeft: 5 }} />
                                    </Tooltip>
                                )}
                            </>
                        )
                    );
                },
            },
            {
                title: 'Объемный вес',
                width: 130,
                align: 'center',
                render: (_: any, record) => {
                    if (record.unitType !== UnitType.Weight) return '';

                    let setting = tariffSettings.find((s) => s.consigneeId == record?.consigneeId && s.countryId == record?.countryId);

                    return (
                        record.volumeWeight && (
                            <>
                                {record.volumeWeight} <span style={{ fontWeight: 500 }}>кг</span>
                                {(!setting || setting.weightType == WeightType.Volume) && (
                                    <Tooltip title='Расчетный вес'>
                                        <CheckCircleFilled style={{ color: '#FFDD2D', marginLeft: 5 }} />
                                    </Tooltip>
                                )}
                            </>
                        )
                    );
                },
            },
            {
                title: 'Тариф',
                width: 100,
                align: 'center',
                render: (_: any, record) => {
                    if (!record.boxGroupId) return;

                    return (
                        <div>
                            {record.flightPrice && (
                                <Flex align='center' gap='middle' className='flight-price'>
                                    <div>
                                        <Tooltip title='Перелет' placement='topRight'>
                                            <div>
                                                <AirplaneIcon style={{ fontSize: 12 }} />
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div>{record.flightPrice}</div>
                                </Flex>
                            )}

                            <Flex align='center' gap='middle' className={record.cargoId ? 'flight-price' : 'truck-price'}>
                                <div>
                                    <Tooltip
                                        title={record.cargoId ? 'Транспортная доставка с перелетом' : 'Транспортная доставка'}
                                        placement='topRight'
                                    >
                                        <div>
                                            <TruckIcon style={{ fontSize: 12, color: record.cargoId ? 'var(--main-green)' : '' }} />
                                        </div>
                                    </Tooltip>
                                </div>
                                <div>{record.truckPrice}</div>
                            </Flex>
                        </div>
                    );
                },
            },
            {
                title: 'Упаковка',
                width: 280,
                onCell: () => ({
                    style: {
                        padding: 0,
                    },
                }),
                render: (_: any, record) => {
                    if (record.unitType == UnitType.Cart) {
                        return (
                            <Tag color='#780606' style={{ fontSize: 14, marginLeft: 5, marginTop: 5, marginBottom: 5 }}>
                                Телега
                            </Tag>
                        );
                    } else if (record.unitType == UnitType.Pallet) {
                        if (record.packages && record.packages.length > 0) {
                            return record.packages?.map((p) => {
                                return (
                                    <Tag
                                        key={p.templateId}
                                        color='green'
                                        style={{ fontSize: 14, marginLeft: 5, marginTop: 5, marginBottom: 5, color: '#000000' }}
                                    >
                                        <span style={{ fontWeight: 600 }}>{p.templateName}</span> : {p.boxQty}
                                    </Tag>
                                );
                            });
                        } else {
                            return (
                                <Tag color='#009a66' style={{ fontSize: 14, marginLeft: 5, marginTop: 5, marginBottom: 5 }}>
                                    Паллет
                                </Tag>
                            );
                        }
                    }
                },
            },
            {
                width: 40,
                align: 'center',
                render: (_, record) => {
                    if (!record.boxGroupId) return;

                    return (
                        record.comment && (
                            <Tooltip placement='topRight' title={record.comment}>
                                <div className='comment-btn'>
                                    <MessageFilled />
                                </div>
                            </Tooltip>
                        )
                    );
                },
            },
            {
                title: `Итого (${currencySign(Currency.Usd)})`,
                width: 140,
                align: 'center',
                onCell: (record) => ({
                    style: {
                        background: '#FFEE96',
                        fontWeight: 600,
                    },
                }),
                render: (_: any, record) => {
                    return (
                        <div style={{ color: record.totalPrice <= 0 ? 'red' : '' }}>
                            <Flex gap='small' justify='center' style={{ color: record.totalPrice <= 0 ? 'red' : '' }}>
                                {record.totalPrice && toFinanceString(record.totalPrice, 2)}
                                {record.isTotalPriceManual && (
                                    <Tooltip title='Счет изменен вручную'>
                                        <FormOutlined />
                                    </Tooltip>
                                )}
                            </Flex>

                            {record.totalPriceFinal != undefined && record.totalPriceFinal != record.totalPrice && (
                                <Tooltip title='Со скидкой'>
                                    <div style={{ background: '#d2dcd8', padding: 3, marginTop: 5 }}>
                                        {toFinanceString(record.totalPriceFinal, 2)}
                                    </div>
                                </Tooltip>
                            )}
                            {record.isPaid && (
                                <Tooltip title='Оплачен' color='green'>
                                    <CheckOutlined style={{ fontSize: 18, color: 'green', marginLeft: 5 }} />
                                </Tooltip>
                            )}
                        </div>
                    );
                },
            },
            {
                title: `Итого (${currencySign(Currency.Rub)})`,
                width: 140,
                align: 'center',
                onCell: (record) => ({
                    style: {
                        fontWeight: 600,
                    },
                }),
                render: (_: any, record) => {
                    return (
                        <div style={{ color: record.totalPriceRub <= 0 ? 'red' : '' }}>
                            {record.totalPrice && toFinanceString(record.totalPriceRub, 2)}
                            {record.totalPriceFinalRub != undefined && record.totalPriceFinalRub != record.totalPriceRub && (
                                <Tooltip title='Со скидкой'>
                                    <div style={{ background: '#d2dcd8', padding: 3, marginTop: 5 }}>
                                        {toFinanceString(record.totalPriceFinalRub, 2)}
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                    );
                },
            },
            {},
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={record.bills}
                pagination={false}
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                    type: 'checkbox',
                    columnWidth: 35,
                }}
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                            onSelectChange([record.id || '']);
                        },
                    };
                }}
            />
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<IBillHeader> = [
            {
                title: 'Дата',
                width: 130,
                align: 'center',
                render: (_, record) => {
                    return record.billDate && dayjs.utc(record.billDate).local().format('DD.MM.YYYY');
                },
            },
            {
                width: 665,
            },
            {
                title: ' ',
                align: 'center',
                width: 40,
                render: (_, record) => {
                    return <Button type='text' icon={<EyeFilled />} onClick={() => setBills(record.bills)} />;
                },
            },
            {
                title: `Общий Счет (${currencySign(Currency.Usd)})`,
                align: 'left',
                width: 300,
                onCell: (record) => ({
                    style: {
                        background: '#FFEE96',
                        fontWeight: 600,
                    },
                }),
                render: (_, record) => {
                    return (
                        <Flex align='center' gap='large'>
                            {record.totalPrice && toFinanceString(record.totalPrice, 2)}
                            {record.totalPriceFinal != undefined && record.totalPriceFinal != record.totalPrice && (
                                <Tooltip title='Со скидкой'>
                                    <div style={{ background: '#d2dcd8', padding: '3px 6px' }}>
                                        {toFinanceString(record.totalPriceFinal, 2)}
                                    </div>
                                </Tooltip>
                            )}
                        </Flex>
                    );
                },
            },
            {
                title: `Общий Счет (${currencySign(Currency.Rub)})`,
                align: 'left',
                width: 300,
                onCell: (record) => ({
                    style: {
                        fontWeight: 600,
                    },
                }),
                render: (_, record) => {
                    return (
                        <Flex align='center' gap='large'>
                            {record.totalPriceRub && toFinanceString(record.totalPriceRub, 2)}
                            {record.totalPriceFinalRub != undefined && record.totalPriceFinalRub != record.totalPriceRub && (
                                <Tooltip title='Со скидкой'>
                                    <div style={{ background: '#d2dcd8', padding: '3px 6px' }}>
                                        {toFinanceString(record.totalPriceFinalRub, 2)}
                                    </div>
                                </Tooltip>
                            )}
                        </Flex>
                    );
                },
            },
            {},
        ];

        return (
            <Table
                rowKey='loadingOn'
                size='small'
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                columns={columns}
                expandable={{ expandedRowRender: expandedBills }}
                dataSource={headers}
                pagination={false}
                scroll={{ y: height + (showFilter ? 0 : 58), x: 'max-content' }}
            />
        );
    };

    return (
        <div ref={containerRef} style={{ height: `calc(100vh - 195px - ${filterHeight}px - ${userId ? 68 : 0}px)` }}>
            {userId && <FormHeader title={`Счета "${user?.login}"`} />}

            {renderToolbar()}
            {showFilter && renderFilter()}
            {renderTable()}

            {openBill && (
                <Bill
                    bills={bills}
                    onClose={() => {
                        setBills([]);
                        setOpenBill(false);
                    }}
                />
            )}

            <Modal
                width={650}
                title={`Изменить "${currentBill?.markingCode}"`}
                open={editTariffOpen}
                okText='ОК'
                onOk={() => editTariffForm.submit()}
                onCancel={() => {
                    editTariffForm.resetFields();
                    setEditTariffOpen(false);
                }}
            >
                <Form
                    colon={false}
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 19 }}
                    onFinish={onBillUpdate}
                    form={editTariffForm}
                    style={{ marginTop: 20 }}
                >
                    <Form.Item name='id' hidden={true}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='boxGroupId' hidden={true}>
                        <Input />
                    </Form.Item>

                    <Form.Item label='Расчетный город'>
                        <Space.Compact style={{ width: '100%' }}>
                            <Form.Item name='setCityRequired' style={{ width: 30 }} valuePropName='checked'>
                                <Checkbox />
                            </Form.Item>
                            <Form.Item
                                name='cityId'
                                rules={[{ required: setCityRequired, message: 'Выберите город' }]}
                                style={{ width: 'calc(100% - 30px)' }}
                            >
                                <Select
                                    disabled={!setCityRequired}
                                    showSearch
                                    allowClear
                                    filterOption={(input: any, option: any) =>
                                        (option?.label as string).toLowerCase().startsWith(input.toLowerCase())
                                    }
                                    filterSort={(a: any, b: any) =>
                                        (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())
                                    }
                                    options={cities.map((c) => {
                                        return { value: c.id, label: c.name };
                                    })}
                                ></Select>
                            </Form.Item>
                        </Space.Compact>
                    </Form.Item>
                    {currentBill?.unitType == UnitType.Weight && (
                        <>
                            <Form.Item label='Вес Брутто'>
                                <Space.Compact style={{ width: '100%' }}>
                                    <Form.Item name='setGrossWeightRequired' style={{ width: 30 }} valuePropName='checked'>
                                        <Checkbox />
                                    </Form.Item>
                                    <Form.Item
                                        name='grossWeight'
                                        rules={[{ required: setGrossWeightRequired, message: 'Выберите город' }]}
                                        style={{ width: 'calc(100% - 30px)' }}
                                    >
                                        <InputNumber
                                            min={0}
                                            decimalSeparator=','
                                            stringMode
                                            disabled={!setGrossWeightRequired}
                                            onChange={(value: number | null) => {
                                                let grossWeight = value || 0;

                                                let setting = tariffSettings.find(
                                                    (s) =>
                                                        s.consigneeId == currentBill?.consigneeId && s.countryId == currentBill?.countryId
                                                );

                                                if (setting && setting.weightType == WeightType.Gross) {
                                                    let totalPrice =
                                                        ((editTariffForm.getFieldValue('flightPrice') || 0) +
                                                            editTariffForm.getFieldValue('truckPrice')) *
                                                        grossWeight;

                                                    editTariffForm.setFieldValue('totalPrice', totalPrice);
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                </Space.Compact>
                            </Form.Item>
                            <Form.Item label='Объемный вес'>
                                <Space.Compact style={{ width: '100%' }}>
                                    <Form.Item name='setVolumeWeightRequired' style={{ width: 30 }} valuePropName='checked'>
                                        <Checkbox />
                                    </Form.Item>
                                    <Form.Item
                                        name='volumeWeight'
                                        rules={[{ required: setVolumeWeightRequired, message: 'Выберите город' }]}
                                        style={{ width: 'calc(100% - 30px)' }}
                                    >
                                        <InputNumber
                                            decimalSeparator=','
                                            min={0}
                                            stringMode
                                            disabled={!setVolumeWeightRequired}
                                            onChange={(value: number | null) => {
                                                let volumeWeight = value || 0;

                                                let setting = tariffSettings.find(
                                                    (s) =>
                                                        s.consigneeId == currentBill?.consigneeId && s.countryId == currentBill?.countryId
                                                );

                                                if (!setting || setting.weightType == WeightType.Volume) {
                                                    let totalPrice =
                                                        ((editTariffForm.getFieldValue('flightPrice') || 0) +
                                                            editTariffForm.getFieldValue('truckPrice')) *
                                                        volumeWeight;

                                                    editTariffForm.setFieldValue('totalPrice', totalPrice);
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                </Space.Compact>
                            </Form.Item>
                        </>
                    )}

                    {currentBill?.unitType != UnitType.Weight && (!currentBill?.packages || currentBill.packages.length <= 0) && (
                        <Form.Item label={currentBill?.unitType == UnitType.Cart ? 'Телега' : 'Паллет'}>
                            <Form.Item name='adjustmentQty'>
                                <InputNumber
                                    addonAfter='шт'
                                    min={0}
                                    step='0.1'
                                    stringMode
                                    precision={2}
                                    onChange={(value: number | null) => {
                                        let adjustmentQty = value || 0;
                                        let totalPrice = adjustmentQty * editTariffForm.getFieldValue('truckPrice');

                                        editTariffForm.setFieldValue('adjustmentQty', adjustmentQty);
                                        editTariffForm.setFieldValue('totalPrice', totalPrice);
                                    }}
                                />
                            </Form.Item>
                        </Form.Item>
                    )}

                    <Form.Item label='Тариф'>
                        <Flex gap='middle'>
                            <Form.Item name='flightPrice'>
                                <InputNumber
                                    decimalSeparator=','
                                    addonAfter={<AirplaneIcon />}
                                    min={0}
                                    precision={2}
                                    step='0.1'
                                    stringMode
                                    disabled={currentBill?.unitType != UnitType.Weight}
                                    onChange={(value: number | null) => {
                                        let flightPrice = value || 0;

                                        let setting = tariffSettings.find(
                                            (s) => s.consigneeId == currentBill?.consigneeId && s.countryId == currentBill?.countryId
                                        );

                                        let weight =
                                            setting && setting.weightType == WeightType.Gross
                                                ? editTariffForm.getFieldValue('grossWeight') || 0
                                                : editTariffForm.getFieldValue('volumeWeight') || 0;

                                        let totalPrice = (flightPrice + editTariffForm.getFieldValue('truckPrice')) * weight;

                                        editTariffForm.setFieldValue('totalPrice', totalPrice);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item name='truckPrice'>
                                <InputNumber
                                    decimalSeparator=','
                                    addonAfter={<TruckIcon />}
                                    min={0}
                                    precision={2}
                                    step='0.1'
                                    stringMode
                                    onChange={(value: number | null) => {
                                        let truckPrice = value || 0;

                                        let totalPrice = 0;
                                        if (currentBill?.unitType == UnitType.Weight) {
                                            let setting = tariffSettings.find(
                                                (s) => s.consigneeId == currentBill?.consigneeId && s.countryId == currentBill?.countryId
                                            );

                                            let weight =
                                                setting && setting.weightType == WeightType.Gross
                                                    ? editTariffForm.getFieldValue('grossWeight') || 0
                                                    : editTariffForm.getFieldValue('volumeWeight') || 0;

                                            totalPrice = ((editTariffForm.getFieldValue('flightPrice') || 0) + truckPrice) * weight;
                                        } else {
                                            if (currentBill?.packages && currentBill?.packages.length > 0) {
                                                currentBill?.packages?.map((p) => {
                                                    totalPrice += ((p.boxQty || 0) / p.qtyPerPallet) * truckPrice;
                                                });
                                            } else {
                                                totalPrice = editTariffForm.getFieldValue('adjustmentQty') * truckPrice;
                                            }
                                        }

                                        editTariffForm.setFieldValue('totalPrice', totalPrice);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item name='totalPrice'>
                                <InputNumber decimalSeparator=',' addonAfter={<SumIcon />} min={0} step='1' precision={2} />
                            </Form.Item>
                        </Flex>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                width={650}
                title={
                    <>
                        Добавить упаковку. (
                        <span
                            style={{
                                color:
                                    packages.map((e) => e.boxQty || 0).reduce((sum, a) => sum + a, 0) == currentBill?.qty ? 'green' : 'red',
                            }}
                        >
                            {packages.map((e) => e.boxQty || 0).reduce((sum, a) => sum + a, 0)}
                        </span>
                        / {currentBill?.qty})
                    </>
                }
                open={openPackageDialog}
                okText='ОК'
                closable={false}
                onOk={() => {
                    onSavePackages();
                }}
                onCancel={() => {
                    setCurrentBill(undefined);
                    setSelectedIds([]);

                    setOpenPackageDialog(false);
                }}
            >
                <Packages dataSource={packages} onChange={(e) => setPackages(e)} />
            </Modal>

            <Modal
                width={300}
                title='Изменить дату отгрузки'
                destroyOnClose={true}
                open={openloadingOnDialog}
                okText='ОК'
                closable={false}
                onOk={() => {
                    loadingOnForm.submit();
                }}
                onCancel={() => {
                    loadingOnForm.resetFields();
                    setCurrentBill(undefined);
                    setSelectedIds([]);

                    setOpenloadingOnDialog(false);
                }}
            >
                <Form
                    colon={false}
                    labelCol={{ span: 9 }}
                    wrapperCol={{ span: 15 }}
                    style={{ marginTop: 20 }}
                    form={loadingOnForm}
                    onFinish={onSetloadingOn}
                >
                    <Form.Item label='Дата' name='loadingOn' rules={[{ required: true, message: 'Укажите дату отгрузки' }]}>
                        <DatePicker format={'DD.MM.YYYY'} />
                    </Form.Item>
                </Form>
            </Modal>

            {currentBill && openBillDialog && (
                <BillModal
                    userId={currentBill?.userId || ''}
                    userLogin={user?.login || ''}
                    billId={currentBill?.id}
                    onCancel={() => setOpenBillDialog(false)}
                    onSave={(value: IBill) => onSaveBill(value)}
                    api={api}
                />
            )}

            {modalContextHolder}
            {notificationContextHolder}
        </div>
    );
};

export default BillHeaders;
