import React, { useState, useEffect } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';

import { useElementSize } from 'usehooks-ts';
import { isMobile } from 'react-device-detect';

import { Modal, Table, Row, Col, Divider, Select, Input, Form, Switch, Tooltip, Flex, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
    CheckOutlined,
    LoadingOutlined,
    ReloadOutlined,
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
    FilterFilled,
    UnorderedListOutlined,
} from '@ant-design/icons';

import Filter from '@controls/filter/filter';
import Toolbar from '@controls/toolbar/toolbar';

import { exception, securityRestriction } from '@extensions/notification';
import { getEnumList, delayAction, toFinanceString } from '@extensions/utils';

import { userLoaded, setFilter } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { serverFetch } from '@src/core/server';
import { IUserSession } from '@entities/user-session';
import { IUser } from '@entities/user';
import { IUserPermission } from '@src/core/entities/user-permission';
import { IUserFilter } from '@entities/user-filter';
import { IWarehouse } from '@entities/warehouse';
import { ICompany } from '@entities/company';
import { ICompanyFilter } from '@entities/company-filter';
import { IUserAccount } from '@entities/user-account';
import { IUserDiscount } from '@entities/user-discount';
import { IUserDiscountFilter } from '@entities/user-discount-filter';
import { IUserDebt } from '@entities/user-debt';
import { IUserDebtFilter } from '@entities/user-debt-filter';

import { UserType, enumLabel as userTypeLabel } from '@enums/user-type';
import { Permission, hasPermission, enumLabel as permissionLabel } from '@enums/permission';
import { enumLabel as discountTypeLabel } from '@enums/discount-type';
import { Currency, enumSign as currencySign } from '@enums/currency';
import { IEnumItem } from '@enums/enum-item';

import { MobileIcon, MobileNoneIcon, BillIcon } from '@icons/index';
import { RubleFilledIcon, UsdFilledIcon, EuroFilledIcon } from '@icons/filled/index';

const filterContext: string = 'Users';

const Users = () => {
    const initFilter: IUserFilter = { isActive: true, isArchived: false };

    const [containerRef, { width, height }] = useElementSize();
    const [filterRef, { width: filterWidth, height: filterHeight }] = useElementSize();

    const navigate = useNavigate();

    const [modal, modalContextHolder] = Modal.useModal();

    const resolved = useResolvedPath('');

    const d = useAppDispatch();

    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const filter = useAppSelector<IUserFilter>((s) => s.filters[filterContext]);

    const [api, contextHolder] = notification.useNotification();

    const [users, setUsers] = useState<Array<IUser>>([]);
    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [currentUser, setCurrentUser] = useState<IUser>();
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
    const [userTypes] = useState(getEnumList(UserType, userTypeLabel));
    const [warehouses, setWarehouses] = useState<Array<IWarehouse>>([]);

    const [loadingPermissions, setLoadingPermissions] = useState<boolean>(false);
    const [userPermissions, setUserPermissions] = useState<Array<IUserPermission>>([]);
    const [permissions] = useState<Array<IEnumItem>>(getEnumList(Permission, permissionLabel));

    const [showFilter, setShowFilter] = useState<boolean>(!isMobile);

    const [companies, setCompanies] = useState<Array<ICompany>>([]);
    const [loadingCompanies, setLoadingCompanies] = useState<boolean>(false);

    const [discounts, setDiscounts] = useState<Array<IUserDiscount>>([]);
    const [loadingDiscounts, setLoadingDiscounts] = useState<boolean>(false);

    const [userDebts, setUserDebts] = useState<Array<IUserDebt>>([]);
    const [loadingUserDebts, setLoadingUserDebts] = useState<boolean>(false);

    const [employees, setEmployees] = useState<Array<IUser>>([]);
    const [loadingEmployees, setLoadingEmployees] = useState<boolean>(false);

    const [accounts, setAccounts] = useState<Array<IUserAccount>>([]);

    useEffect(() => {
        if (!refreshRequired) return;

        let cleanup = false;

        const fetchData = async () => {
            setLoadingUsers(true);

            setUsers([]);
            setWarehouses([]);
            setSelectedIds([]);
            setCurrentUser(undefined);

            let promises = [
                await serverFetch('users', { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения пользователей', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('warehouses', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения складов', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setUsers(result[0][0]);
                setWarehouses(result[0][1]);

                setLoadingUsers(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        if (!filter) {
            d(setFilter(initFilter, filterContext));

            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    useEffect(() => {
        if (currentUser) {
            if (currentUser.type === UserType.System) {
                loadPermissions(currentUser.id);
            } else if (currentUser.type === UserType.MainClient || currentUser.type === UserType.Supplier) {
                loadUserDebts(currentUser.id);
                loadDiscounts(currentUser.id);
                loadCompanies(currentUser.id);
                loadEmployees(currentUser.id);
                loadAccounts(currentUser.id);
            }
        } else {
            setUserPermissions([]);
            setCompanies([]);
        }
    }, [currentUser]);

    const loadPermissions = (id: string | undefined) => {
        if (!id) return;

        setUserPermissions([]);
        setLoadingPermissions(true);

        serverFetch(`users/${id}/permissions`, { method: 'GET' })
            .then((data) => {
                let entities: Array<IUserPermission> = [];
                permissions.map((p: IEnumItem) => {
                    let permission = data.find((e: IUserPermission) => e.permissionCode === p.value);
                    if (permission) {
                        entities.push({ userId: id, name: permissionLabel(p.value), permissionCode: p.value, isActive: true });
                    } else if (p.value < 0) {
                        entities.push({ userId: id, name: permissionLabel(p.value), permissionCode: p.value, isActive: false });
                    }
                });

                setUserPermissions(entities);
                setLoadingPermissions(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения прав', ex, () => d(userLoaded(undefined)));
                setLoadingPermissions(false);
            });
    };

    const loadUserDebts = (id: string | undefined) => {
        if (!id) return;

        setUserDebts([]);
        setLoadingUserDebts(true);

        var userDebtFilter: IUserDebtFilter = {
            userId: currentUser?.id,
            isArchived: false,
        };

        serverFetch(`userdebts`, { method: 'GET', queryParams: userDebtFilter })
            .then((data) => {
                setUserDebts(data);
                setLoadingUserDebts(false);
            })
            .catch((ex) => {
                setLoadingUserDebts(false);
                exception(api, 'Ошибка получения долгов перед клиентом', ex, () => d(userLoaded(undefined)));
            });
    };

    const loadDiscounts = (id: string | undefined) => {
        if (!id) return;

        setDiscounts([]);
        setLoadingDiscounts(true);

        var discountFilter: IUserDiscountFilter = {
            userId: currentUser?.id,
        };

        serverFetch(`userdiscounts`, { method: 'GET', queryParams: discountFilter })
            .then((data) => {
                setDiscounts(data);
                setLoadingDiscounts(false);
            })
            .catch((ex) => {
                setLoadingDiscounts(false);
                exception(api, 'Ошибка получения скидок', ex, () => d(userLoaded(undefined)));
            });
    };

    const loadCompanies = (id: string | undefined) => {
        if (!id) return;

        setCompanies([]);
        setLoadingCompanies(true);

        var companyFilter: ICompanyFilter = {
            userId: currentUser?.id,
        };

        serverFetch(`companies`, { method: 'GET', queryParams: companyFilter })
            .then((data) => {
                setCompanies(data);
                setLoadingCompanies(false);
            })
            .catch((ex) => {
                setLoadingCompanies(false);
                exception(api, 'Ошибка получения контрагентов', ex, () => d(userLoaded(undefined)));
            });
    };

    const loadAccounts = (id: string | undefined) => {
        if (!id) return;

        setAccounts([]);

        serverFetch(`users/${id}/accounts`, { method: 'GET' })
            .then((data) => {
                setAccounts(data);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения счетов', ex, () => d(userLoaded(undefined)));
            });
    };

    const loadEmployees = (id: string | undefined) => {
        if (!id) return;

        setEmployees([]);
        setLoadingEmployees(true);

        serverFetch(`users/${id}/employees`, { method: 'GET' })
            .then((data) => {
                setEmployees(data);
                setLoadingEmployees(false);
            })
            .catch((ex) => {
                setLoadingEmployees(false);
                exception(api, 'Ошибка получения сотрудников', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDelete = () => {
        if (!currentUser) return;

        serverFetch(`users/${currentUser.id}`, { method: 'DELETE' })
            .then(() => {
                onSelectChange([]);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления пользователя', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        disabled: loadingUsers,
                        key: 'refresh',
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Добавить',
                        key: 'add',
                        type: 'primary',
                        icon: <PlusOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageUsers)) {
                                securityRestriction(api, [Permission.ManageUsers]);
                                return;
                            }

                            navigate(`${resolved.pathname}/new`);
                        },
                    },
                    {
                        label: `${
                            hasPermission(userSession.permissions, Permission.ManageUsers) ? 'Изменить' : 'Информация о пользователе'
                        }`,
                        key: 'edit',
                        disabled: !currentUser,
                        icon: <EditOutlined />,
                        onClick: () => {
                            navigate(`${resolved.pathname}/${currentUser?.id}`);
                        },
                    },
                    {
                        label: 'Удалить',
                        key: 'delete',
                        disabled: !currentUser,
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.DeleteUser)) {
                                securityRestriction(api, [Permission.DeleteUser]);
                                return;
                            }

                            modal.confirm({
                                title: `Удалить пользователя "${currentUser?.login}"?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'Удалить',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDelete();
                                },
                            });
                        },
                    },
                    {
                        label: 'Заказы пользователя',
                        key: 'orders',
                        disabled: !currentUser || currentUser.type === UserType.System || currentUser.type == UserType.Employee,
                        icon: <UnorderedListOutlined />,
                        onClick: () => {
                            navigate(`/orders/${currentUser?.id}`);
                        },
                    },
                    {
                        label: 'Счета пользователя',
                        key: 'bills',
                        disabled: !currentUser || currentUser.type === UserType.System || currentUser.type == UserType.Employee,
                        icon: <BillIcon />,
                        onClick: () => {
                            navigate(`/finance/bills/${currentUser?.id}`);
                        },
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let entity = users.find((o) => o.id === selectedRowKeys[0]);
            setCurrentUser(entity);
        } else {
            setCurrentUser(undefined);
        }
    };

    const renderFilter = () => {
        return (
            <div ref={filterRef}>
                <Filter
                    items={[
                        <Input
                            style={{ width: 250 }}
                            key='searchText'
                            placeholder='Логин / ФИО / Email'
                            value={filter?.searchText}
                            onChange={(data: any) => {
                                d(setFilter({ ...filter, searchText: data.target.value }, filterContext));
                            }}
                        />,
                        <Select
                            key='userType'
                            placeholder='Тип'
                            value={filter?.types}
                            size='middle'
                            mode='multiple'
                            maxTagCount='responsive'
                            allowClear
                            style={{ width: 200 }}
                            onChange={(value: any) => d(setFilter({ ...filter, types: value }, filterContext))}
                            options={userTypes.map((t) => {
                                return { value: t.value, label: t.label };
                            })}
                        />,

                        <Select
                            key='warehouseCode'
                            placeholder='Склад'
                            value={filter?.warehouseCode}
                            size='middle'
                            allowClear
                            style={{ width: 120 }}
                            onChange={(value: any) => d(setFilter({ ...filter, warehouseCode: value }, filterContext))}
                            options={warehouses.map((t) => {
                                return { value: t.code, label: t.code };
                            })}
                        />,

                        <Form.Item key='isActive' label='Активные' style={{ margin: 0 }}>
                            <Switch
                                checked={filter?.isActive}
                                onChange={(value: any) => {
                                    d(setFilter({ ...filter, isActive: value }, filterContext));
                                }}
                            />
                        </Form.Item>,
                        hasPermission(userSession.permissions, Permission.FullAccess) && (
                            <Form.Item key='isArchived' label='Архив' style={{ margin: 0 }}>
                                <Switch
                                    checked={filter?.isArchived}
                                    onChange={(value: any) => {
                                        d(setFilter({ ...filter, isArchived: value }, filterContext));
                                    }}
                                />
                            </Form.Item>
                        ),
                    ]}
                    onClear={() => d(setFilter(initFilter, filterContext))}
                />
            </div>
        );
    };

    const renderUsersTable = () => {
        const columns: ColumnsType<IUser> = [
            {
                title: 'Логин',
                dataIndex: 'login',
                width: 160,
            },
            {
                title: 'Активно',
                dataIndex: 'isActive',
                width: 80,
                align: 'center',
                render: (_: any, record: IUser) => {
                    return record.isActive ? <CheckOutlined /> : '';
                },
            },
            {
                title: 'Тип',
                width: 140,
                align: 'center',
                render: (_: any, record: IUser) => {
                    return userTypeLabel(record.type);
                },
            },
            {
                title: 'Склад',
                dataIndex: 'warehouseCode',
                width: 80,
                render: (_, record) => {
                    return (
                        <>
                            <div className='transitWarehouseTag'>{record.warehouseCode}</div>
                        </>
                    );
                },
            },
            {
                title: 'ФИО',
                dataIndex: 'fullName',
                width: 300,
            },
            {
                title: 'Телефон',
                dataIndex: 'phone',
                width: 150,
            },
            {
                width: 35,
                align: 'center',
                render: (_, record) => {
                    if (record.type === UserType.System) return '';

                    return record.firebaseToken ? (
                        <Tooltip title='Приложение "My Freshline" используется'>
                            <MobileIcon style={{ color: 'green', fontSize: 20 }} />
                        </Tooltip>
                    ) : (
                        <Tooltip title='Приложение "My Freshline" не используется.'>
                            <MobileNoneIcon style={{ color: 'red', fontSize: 20 }} />
                        </Tooltip>
                    );
                },
            },
            {
                title: 'Настройки',
                width: 250,
                render: (_, record) => {
                    if (!record.userSettings) return;

                    return (
                        <ul style={{ margin: 0 }}>
                            {record.userSettings.showBills && <li>Отображать счета</li>}
                            {record.userSettings.showNotifications && <li>Отображать уведомления</li>}
                        </ul>
                    );
                },
            },
            {
                title: 'Email',
                dataIndex: 'email',
                width: 300,
            },
            {},
        ];

        return (
            <Table
                virtual={true}
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={users}
                loading={{
                    spinning: loadingUsers,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                    type: 'radio',
                    columnWidth: 35,
                }}
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                            onSelectChange([record.id || '']);
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: height }}
            />
        );
    };

    const renderPermissionsTable = () => {
        const columns: ColumnsType<IUserPermission> = [
            {
                title: '',
                width: 40,
                onCell: (record: IUserPermission, _) => ({
                    colSpan: record.permissionCode < 0 ? 2 : 1,
                    style: { fontWeight: record.permissionCode < 0 ? 600 : 100 },
                }),
                render: (_, record) => {
                    if (record.permissionCode < 0) {
                        return <span className='permissionGroup'>{permissionLabel(record.permissionCode)}</span>;
                    }

                    return '';
                },
            },
            {
                title: '',
                dataIndex: 'name',
                onCell: (record: IUserPermission, _) => ({
                    colSpan: record.permissionCode < 0 ? 0 : 1,
                }),
            },
        ];

        return (
            <Table
                rowKey='permissionCode'
                showHeader={false}
                size='small'
                columns={columns}
                dataSource={userPermissions}
                loading={{
                    spinning: loadingPermissions,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                pagination={false}
                scroll={{ y: `calc(100vh - 150px)` }}
            />
        );
    };

    const renderUserDebtsTable = () => {
        const columns: ColumnsType<IUserDebt> = [
            {
                title: 'Название',
                width: 200,
                render: (_: any, record) => {
                    return record.debtName;
                },
            },
            {
                title: 'Сумма',
                width: 120,
                render: (_: any, record) => {
                    return (
                        <>
                            {toFinanceString(record.amount, 2)} {currencySign(record.currency)}
                        </>
                    );
                },
            },
            {
                title: 'Комментарий',
                width: 260,
                key: 'comment',
                dataIndex: 'comment',
            },
            {},
        ];

        return (
            <>
                <Divider style={{ background: 'var(--main-red)' }} orientation='left'>
                    Долги перед клиентом
                </Divider>

                <Table
                    rowKey='id'
                    size='small'
                    columns={columns}
                    dataSource={userDebts}
                    loading={{
                        spinning: loadingUserDebts,
                        indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                    }}
                    pagination={false}
                    scroll={{ y: `calc(100vh - 150px)` }}
                />
            </>
        );
    };

    const renderDiscountsTable = () => {
        const columns: ColumnsType<IUserDiscount> = [
            {
                title: 'Тип',
                width: 250,
                render: (_: any, record) => {
                    return discountTypeLabel(record.type);
                },
            },
            {
                title: 'Скидка',
                width: 180,
                render: (_: any, record) => {
                    return <>{record.value} $</>;
                },
            },
            {},
        ];

        return (
            <>
                <Divider orientation='left'>Скидки</Divider>

                <Table
                    rowKey='id'
                    size='small'
                    columns={columns}
                    dataSource={discounts}
                    loading={{
                        spinning: loadingDiscounts,
                        indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                    }}
                    pagination={false}
                    scroll={{ y: `calc(100vh - 150px)` }}
                />
            </>
        );
    };

    const renderCompaniesTable = () => {
        const columns: ColumnsType<ICompany> = [
            {
                title: 'Название',
                dataIndex: 'legalName',
                width: 250,
            },
            {
                title: 'ИНН',
                width: 120,
                dataIndex: 'inn',
            },
            {
                title: 'Юр. адрес',
                width: 300,
                dataIndex: 'legalAddress',
            },
            {
                title: 'Основной',
                width: 90,
                align: 'center',
                render: (_: any, record) => {
                    return record.isMain ? <CheckOutlined /> : '';
                },
            },
            {},
        ];

        return (
            <>
                <Divider orientation='left'>Контрагенты</Divider>
                <Table
                    rowKey='id'
                    size='small'
                    columns={columns}
                    dataSource={companies}
                    loading={{
                        spinning: loadingCompanies,
                        indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                    }}
                    pagination={false}
                    scroll={{ y: `calc(100vh - 150px)` }}
                />
            </>
        );
    };

    const renderEmployeesTable = () => {
        const columns: ColumnsType<IUser> = [
            {
                title: 'Логин',
                dataIndex: 'login',
                width: 250,
            },
            {
                title: 'Разрешения',
                width: 150,
                align: 'left',
                render: (_, record) => {
                    return (
                        <ul>
                            {record.userSettings.showBills && <li>Счета</li>}
                            {record.userSettings.showNotifications && <li>Уведомления</li>}
                        </ul>
                    );
                },
            },
            {
                title: 'Активно',
                dataIndex: 'isActive',
                width: 80,
                align: 'center',
                render: (_: any, record: IUser) => {
                    return record.isActive ? <CheckOutlined /> : '';
                },
            },
            {
                title: 'ФИО',
                dataIndex: 'fullName',
                width: 300,
            },
            {
                title: 'Телефон',
                dataIndex: 'phone',
                width: 150,
            },
            {
                width: 35,
                align: 'center',
                render: (_, record) => {
                    return record.firebaseToken ? (
                        <Tooltip title='Приложение "My Freshline" используется'>
                            <MobileIcon style={{ color: 'green', fontSize: 20 }} />
                        </Tooltip>
                    ) : (
                        <Tooltip title='Приложение "My Freshline" не используется.'>
                            <MobileNoneIcon style={{ color: 'red', fontSize: 20 }} />
                        </Tooltip>
                    );
                },
            },
            {
                title: 'Email',
                dataIndex: 'email',
                width: 300,
            },
            {},
        ];

        return (
            <>
                <Divider orientation='left' style={{ marginTop: 40 }}>
                    Сотрудники
                </Divider>
                <Table
                    rowKey='id'
                    size='small'
                    columns={columns}
                    dataSource={employees}
                    loading={{
                        spinning: loadingEmployees,
                        indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                    }}
                    pagination={false}
                    scroll={{ y: `calc(50vh - 125px)`, x: 'max-content' }}
                />
            </>
        );
    };

    const renderAccounts = () => {
        return (
            <div style={{ marginBottom: 40 }}>
                <Divider orientation='left'>Cчета</Divider>
                <Flex justify='flex-start' align='center' gap='large'>
                    {accounts.map((a) => {
                        return (
                            <Flex key={a.currency} align='center'>
                                {a.currency == Currency.Rub ? (
                                    <RubleFilledIcon />
                                ) : a.currency == Currency.Usd ? (
                                    <UsdFilledIcon />
                                ) : (
                                    <EuroFilledIcon />
                                )}
                                <div style={{ marginLeft: 2, fontSize: 18, fontWeight: 600 }}>{toFinanceString(a.amount || 0, 2)}</div>
                            </Flex>
                        );
                    })}
                </Flex>
            </div>
        );
    };

    return (
        <div ref={containerRef} style={{ height: `calc(100vh - 190px - ${filterHeight}px)` }}>
            <Row>
                <Col span={isMobile ? 24 : 14}>
                    {renderToolbar()}
                    {showFilter && renderFilter()}
                    {renderUsersTable()}
                </Col>
                {!isMobile && currentUser && currentUser.type === UserType.System && (
                    <Col offset={1} span={9}>
                        <Divider orientation='left'>Разрешения</Divider>
                        {renderPermissionsTable()}
                    </Col>
                )}
                {!isMobile && currentUser && currentUser.type !== UserType.System && currentUser.type !== UserType.Employee && (
                    <Col offset={1} span={9}>
                        {accounts.length > 0 && renderAccounts()}
                        {userDebts.length > 0 && renderUserDebtsTable()}
                        {discounts.length > 0 && renderDiscountsTable()}
                        {companies.length > 0 && renderCompaniesTable()}
                        {employees.length > 0 && renderEmployeesTable()}
                    </Col>
                )}
            </Row>

            {contextHolder}
            {modalContextHolder}
        </div>
    );
};

export default Users;
